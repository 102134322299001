import { FS } from '../constants';
import { generateToDocFn } from '../types/Document';
import {
  EMPTY_GLOBAL_CONFIG,
  GlobalService,
  GLOBAL_CONFIG_DOC_ID,
  IGlobalConfig
} from '../types/GlobalConfig';
import { LoadingValue, store, useMappedLoadingValue } from './db';
import {
  createDocumentListenerGetter,
  useDocumentListener
} from './documentListener';

const toConfigDoc = generateToDocFn<IGlobalConfig>();

const configDocRef = () =>
  store().collection(FS.globalConfig).doc(GLOBAL_CONFIG_DOC_ID);

const cache = createDocumentListenerGetter<IGlobalConfig>(
  () => configDocRef(),
  toConfigDoc,
  EMPTY_GLOBAL_CONFIG
);

export const useGlobalConfig = (): LoadingValue<IGlobalConfig> => {
  return useMappedLoadingValue(useDocumentListener(cache('')), (d) => {
    const defaults = EMPTY_GLOBAL_CONFIG();
    if (!d) {
      return defaults;
    }
    return {
      ...defaults,
      ...d.data,
      services: {
        ...defaults.services,
        ...d.data.services
      }
    };
  });
};

export const enableService = (key: GlobalService, value: boolean) => {
  store().runTransaction(async (t) => {
    const ref = configDocRef();
    const config = await t.get(ref);
    const d = (config.data() as IGlobalConfig) || EMPTY_GLOBAL_CONFIG();
    return t.set(ref, {
      ...d,
      services: {
        ...d.services,
        [key]: value
      }
    });
  });
};
