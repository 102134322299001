import {
  Button,
  capitalize,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core';
import firebase from 'firebase/app';
import { snakeCase, sortBy } from 'lodash';
import md5 from 'md5';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Menu } from '../../components/AdminMenu';
import { ButtonWithPromise } from '../../components/ButtonWithPromise';
import { Dropzone } from '../../components/Dropzone';
import { PlainLoader } from '../../components/Loader';
import { ProductHuntBanner } from '../../components/ProductHuntBanner';
import { onlyForAdmins } from '../../components/ProtectedAdminPage';
import { SafeExecuteButton } from '../../components/SafeExecuteButton';
import { SEO } from '../../components/SEO';
import { FS } from '../../constants';
import { useErrorLogger } from '../../hooks/useErrorLogger';
import { usePromise } from '../../hooks/usePromise';
import Layout, { Wrapper } from '../../layouts/Layout';
import {
  enableService,
  useGlobalConfig
} from '../../services/admin-globalConfig';
import { toMonitoredBlogDoc } from '../../services/admin-rankings';
import { allWebsitesStore } from '../../services/admin-websites';
import { callFirebaseFunction } from '../../services/cf';
import {
  batchSetDocs,
  batchUpdateDocs,
  store,
  useMappedLoadingValue
} from '../../services/db';
import { readCsvRows } from '../../services/file';
import { toUserDoc } from '../../services/users';
import styled from '../../styled';
import { Doc, generateToDocFn } from '../../types/Document';
import { GlobalService, IGlobalConfig } from '../../types/GlobalConfig';
import { UserPublic } from '../../types/User';
import { Website } from '../../types/Website';

export type Counter = {
  count: number;
  original: number;
  retweet: number;
  own: number;
};

export type RankingDailyTwitterCounts = {
  blogUrl: string;
  blogId: string;
  total: Counter;
  byPage: { [pathname: string]: Counter };
  lastUpdateAt: firebase.firestore.Timestamp;
  dateKey: string;
};

const toRankingDailyTwitterCountsDoc = generateToDocFn<
  RankingDailyTwitterCounts
>();

const useFileContent = (file: File) => {
  return useMappedLoadingValue(
    usePromise(() => readCsvRows(file, { quoteChar: '"' }), [file]),
    (rows) => {
      return rows
        .slice(1)
        .map((r) => {
          const [
            firstName,
            lastName,
            email,
            joinDate,
            location,
            headline,
            bio,
            circleProfileUrl,
            website,
            twitterUrl
          ] = r;
          return {
            email,
            firstName,
            lastName,
            joinDate: new Date(joinDate).valueOf(),
            location,
            headline,
            bio,
            circleProfileUrl,
            twitterUrl,
            website
          };
        })
        .filter((u) => !!u.joinDate);
    }
  );
};

const UserImportDialog = ({
  open,
  onClose,
  file
}: {
  open: boolean;
  onClose: () => void;
  file: File;
}) => {
  const [rows, loading, error] = useFileContent(file);
  useErrorLogger(error);
  if (rows) {
    console.log('ROWS', rows);
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Import users</DialogTitle>
      <DialogContent>
        {loading && <PlainLoader height={300} />}
        {!!rows && <div>Check console to inspect parsing results.</div>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <ButtonWithPromise
          pending="Importing..."
          variant="contained"
          color="primary"
          disabled={!rows}
          onClick={async () => {
            if (rows) {
              return callFirebaseFunction('user-importUsers', {
                users: rows
              });
            }
          }}
        >
          Import
        </ButtonWithPromise>
      </DialogActions>
    </Dialog>
  );
};

const UserImport = () => {
  const [file, setFile] = useState<File | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div>
      <Typography variant="h5" component="h1" gutterBottom>
        User Import
      </Typography>
      <Dropzone
        onDrop={(d) => {
          if (d.length > 1) {
            enqueueSnackbar(
              'Can only handle one file - ignoring everything apart from the first.',
              { variant: 'info' }
            );
          }
          const f = d[0] || null;
          if (f && f.type !== 'text/csv') {
            enqueueSnackbar('Please use a CSV file.', { variant: 'error' });
            return;
          }
          setFile(f);
        }}
      />
      {file && (
        <UserImportDialog
          open={!!file}
          onClose={() => setFile(null)}
          file={file}
        />
      )}
    </div>
  );
};

type CircleCommunityMember = {
  email: string;
  user_id: number;
  public_uid: string;
  avatar_url: string;
  profile_url: string;
}; // and many more we're not interested in

type RssUrl = {
  type: 'application/rss+xml' | 'application/atom+xml';
  url: string;
};
const Migrations = () => {
  return (
    <div>
      <Typography variant="h5" component="h1" gutterBottom>
        Migrations
      </Typography>

      <div>
        <SafeExecuteButton
          variant="contained"
          color="primary"
          onClick={async (execute) => {
            const websites = await allWebsitesStore().get();
            const results = await Promise.all(
              websites.map(async (w) => {
                const rssUrl = await callFirebaseFunction<RssUrl | null>(
                  'rss-getRssUrl',
                  { url: w.data.url }
                );
                return {
                  website: w,
                  rssUrl: {
                    before: w.data.rssUrl,
                    after: rssUrl?.url || ''
                  }
                };
              })
            );

            const changeCount = results.filter(
              (r) => r.rssUrl.before !== r.rssUrl.after
            ).length;
            const emptyCount = results.filter(
              (r) => !r.rssUrl.before && !r.rssUrl.after
            );

            console.log({
              changeCount,
              emptyCount,
              results
            });

            if (execute) {
              const toUpdate = results.filter(
                (r) => !r.rssUrl.before && r.rssUrl.after
              );
              await batchUpdateDocs(
                toUpdate.map<Doc<Website>>((t) => ({
                  ...t.website,
                  data: {
                    ...t.website.data,
                    rssUrl: t.rssUrl.after
                  }
                })),
                (d) => ({ rssUrl: d.rssUrl })
              );
            }
          }}
        >
          Infer RSS urls
        </SafeExecuteButton>
      </div>

      <div>
        <SafeExecuteButton
          variant="contained"
          color="primary"
          onClick={async (execute) => {
            const users = await store()
              .collection(FS.users)
              .get()
              .then((s) => s.docs.map(toUserDoc));
            const publicUsers = users.map((doc) => {
              const d = doc.data;

              const publicDoc: Doc<UserPublic> = {
                id: doc.id,
                collection: FS.usersPublic,
                data: {
                  email: d.settings.showEmail ? d.email : '',
                  firstName: d.firstName,
                  lastName: d.lastName,
                  joinDate: d.joinDate,
                  location: d.location,
                  headline: d.headline,
                  bio: d.bio,
                  circleUserId: d.circleUserId,
                  circlePublicUid: d.circlePublicUid,
                  circleProfileUrl: d.circleProfileUrl,
                  twitterUrl: d.twitterUrl,
                  roles: d.roles,
                  avatarUrl: d.avatarUrl
                }
              };
              return publicDoc;
            });

            console.log(publicUsers);

            if (execute) {
              await batchSetDocs(publicUsers);
            }
          }}
        >
          Sync public user collection
        </SafeExecuteButton>
      </div>

      <div>
        <SafeExecuteButton
          variant="contained"
          color="primary"
          onClick={async (execute) => {
            const response = await callFirebaseFunction('user-syncCircleData', {
              execute
            });
            console.log(response);
          }}
        >
          Seed circle data
        </SafeExecuteButton>
      </div>

      <div>
        <SafeExecuteButton
          variant="contained"
          color="primary"
          onClick={async (execute) => {
            const response = await callFirebaseFunction('user-_resyncAvatars', {
              execute
            });
            console.log(response);
          }}
        >
          Resync avatars
        </SafeExecuteButton>
      </div>

      <div>
        <SafeExecuteButton
          variant="contained"
          color="primary"
          onClick={async (execute) => {
            const docs = await store()
              .collection(FS.monitoredBlog)
              .get()
              .then((s) => s.docs.map(toMonitoredBlogDoc));
            docs.forEach((d) => {
              d.data.id = d.id;
            });
            console.log(docs);
            if (execute) {
              await batchUpdateDocs(docs, (d) => ({ id: d.id }));
            }
          }}
        >
          Add id to MonitoredBlogs
        </SafeExecuteButton>
      </div>

      <div>
        <SafeExecuteButton
          variant="contained"
          color="primary"
          onClick={async (execute) => {
            const docs = await store()
              .collection('rankingDailyTwitterCountsV1')
              .get()
              .then((s) => s.docs.map(toRankingDailyTwitterCountsDoc));
            docs.forEach((d) => {
              d.data.blogId = md5(d.data.blogUrl);
              d.id = `${d.data.blogId}-${d.data.dateKey}`;
            });
            console.log(docs);
            if (execute) {
              await batchSetDocs(docs);
            }
          }}
        >
          Migrate RankingDailyTwitterCounts
        </SafeExecuteButton>
      </div>
    </div>
  );
};

const Services = ({ config }: { config: IGlobalConfig }) => {
  const options: {
    key: GlobalService;
    label: string;
    checked: boolean;
  }[] = Object.entries(config.services).map(([key, checked]) => ({
    key: key as GlobalService,
    checked: checked as boolean,
    label: snakeCase(key).split('_').map(capitalize).join(' ')
  }));
  return (
    <div>
      <Typography variant="h5" component="h1" gutterBottom>
        Services
      </Typography>
      <Card>
        <CardContent>
          <Table>
            <TableBody>
              {sortBy(options, (o) => o.key).map((o) => (
                <TableRow key={o.key}>
                  <TableCell>{o.label}</TableCell>
                  <TableCell align="right">
                    <Switch
                      checked={o.checked}
                      onChange={(ev) => enableService(o.key, ev.target.checked)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};

const PageSection = styled<'section', { collapse?: boolean }>('section')`
  margin-bottom: ${(p) => (p.collapse ? 0 : p.theme.spacing(6))}px;
`;

const IndexPage = () => {
  const [config] = useGlobalConfig();
  return (
    <Layout>
      <ProductHuntBanner />
      <SEO
        title="Admin"
        siteUrl="https://bloggingfordevs.com"
        description="Admin section, lookout!"
        pathname="admin/"
        noIndex
      />
      <Wrapper>
        <Menu />
        <div
          style={{
            margin: '0 auto',
            textAlign: 'center'
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Admin
          </Typography>
        </div>
        <PageSection>
          {config ? <Services config={config} /> : <PlainLoader height={100} />}
        </PageSection>
        <PageSection>
          <UserImport />
        </PageSection>

        <PageSection>
          <Migrations />
        </PageSection>
      </Wrapper>
    </Layout>
  );
};

export default onlyForAdmins(IndexPage);
