export interface IGlobalConfig {
  services: {
    twitter: boolean;
    rss: boolean;
  };
}

export type GlobalService = keyof IGlobalConfig['services'];

export const EMPTY_GLOBAL_CONFIG = (): IGlobalConfig => ({
  services: {
    twitter: true,
    rss: true
  }
});

export const GLOBAL_CONFIG_DOC_ID = 'config';
