import { Button, Dialog, DialogContent } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import styled from '../styled';
import { ButtonWithPromise, ButtonWithPromiseProps } from './ButtonWithPromise';

type Props<T> = {
  onClick: (execute: boolean) => Promise<T | void>;
} & Omit<ButtonWithPromiseProps<T>, 'onClick' | 'pending'>;

export const ButtonContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  > * {
    margin-right: ${(p) => p.theme.spacing()}px;
    margin-bottom: ${(p) => p.theme.spacing()}px;
  }
`;

export const SafeExecuteButton = <T extends unknown>(props: Props<T>) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const { onClick, children, ...other } = props;

  const execute = (exec: boolean) => {
    const type = exec ? 'Execution' : 'Dry Run';
    return onClick(exec).then(
      (x) => {
        enqueueSnackbar(`${type} successful!`, {
          variant: 'success'
        });
        return x;
      },
      (err) => {
        console.error(err);
        enqueueSnackbar(`${type} failed!`, {
          variant: 'error'
        });
        return Promise.reject(err);
      }
    );
  };

  return (
    <>
      <Button
        style={{ marginBottom: '6px' }}
        {...other}
        onClick={() => setOpen(true)}
      >
        {children}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <ButtonContainer>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <ButtonWithPromise
              variant="contained"
              color="secondary"
              pending="Executing..."
              onClick={() => execute(true)}
            >
              Execute
            </ButtonWithPromise>
            <ButtonWithPromise
              variant="contained"
              color="primary"
              pending="Executing..."
              onClick={() => execute(false)}
            >
              Dry run
            </ButtonWithPromise>
          </ButtonContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};
