import { DateTime } from 'luxon';
import { FS } from '../constants';
import { Doc, generateToDocFn } from '../types/Document';
import { MonitoredBlog, RankingStats } from '../types/Ranking';
import {
  CollectionListener,
  createSingleCollectionListenerStore,
  useCollectionListener
} from './collectionListener';
import { store } from './db';
import { now } from './time';

export const toMonitoredBlogDoc = generateToDocFn<MonitoredBlog>((d) => {
  const { screenshotUrl } = d;
  if (typeof screenshotUrl === 'string') {
    d.screenshotUrl = {
      s: '',
      m: '',
      l: screenshotUrl as string
    };
  }
  d.createdAt = typeof d.createdAt === 'number' ? d.createdAt : 1605193655726; // Nov 12, just as approximation
  return d;
});

const monitoredBlogsStore = createSingleCollectionListenerStore(
  () =>
    new CollectionListener(
      store().collection(FS.monitoredBlog),
      toMonitoredBlogDoc
    )
);

export const monitoredBlogsCollection = () =>
  store().collection(FS.monitoredBlog);

export const useMonitoredBlogs = () => {
  return useCollectionListener(monitoredBlogsStore());
};

export const deleteMonitoredBlog = (blogId: string) => {
  return monitoredBlogsCollection().doc(blogId).delete();
};

const toRankingStatsDoc = generateToDocFn<RankingStats>();
const rankingStatsStore = createSingleCollectionListenerStore(
  () =>
    new CollectionListener(
      store().collection(FS.rankingStats),
      toRankingStatsDoc
    )
);

export const useRankingStats = () => {
  return useCollectionListener(rankingStatsStore());
};

export const EMPTY_RANKING_STATS_DOC = (id: string): Doc<RankingStats> => ({
  id,
  collection: FS.rankingStats,
  data: {
    lastDateKey: DateTime.utc().toISODate(),
    updatedAt: now(),
    total: [],
    byPage: {}
  }
});
