import {
  CollectionListener,
  createSingleCollectionListenerStore,
  useCollectionListener
} from './collectionListener';
import { toWebsiteDoc, websitesCollection } from './website';

export const allWebsitesStore = createSingleCollectionListenerStore(
  () => new CollectionListener(websitesCollection(), toWebsiteDoc)
);
export const useAllWebsites = () => useCollectionListener(allWebsitesStore());
